import emotion.react.Global
import emotion.react.styles
import react.FC
import react.dom.html.ReactHTML.body
import web.cssom.*

data object Style {

    val colorBright = Color("#E6E5DD")
    val colorAccent1 = Color("#295082")
    val colorAccent2 = Color("#0F1427")
    val colorAccent3 = Color("#1681A1")
    val colorAccent2Light = Color("#0F142755")

    val scale = 1.0.rem

    val sitePadding = 10.pct
    val siteContentWidth = 80.pct
    val maxContentWidth = 800.px

    val fontSizeTiny = 0.8 * scale
    val fontSizeSmall = 1 * scale
    val fontSizeMedium = 1.2 * scale
    val fontSizeLarge = 2 * scale
    val fontSizeXLarge = 4 * scale
    val fontSmall = Font(FontWeight.normal, fontSizeSmall, FontFamily.sansSerif)
    val fontLink = Font(FontWeight.normal, fontSizeSmall, FontFamily.sansSerif)
    val fontQuote = Font(FontWeight.bold, fontSizeMedium, FontFamily.sansSerif)
    val fontMenu = Font(integer(600), fontSizeMedium, FontFamily.sansSerif)
    val fontTitleSmall = Font(FontWeight.normal, fontSizeMedium, FontFamily.sansSerif)
    val fontTitle = Font(integer(700), fontSizeXLarge, FontFamily.sansSerif)

    val fontTextButton = Font(integer(400), fontSizeSmall, FontFamily.sansSerif)

    val paddingTopBotMedium = 8 * scale
    val paddingTopBotTiny = paddingTopBotMedium / 4
    val paddingTopBotSmall = paddingTopBotMedium / 2
    val paddingTopBotLarge = 2 * paddingTopBotMedium

    val paddingButtonSmall = 0.4 * scale
    val paddingButtonMedium = 0.8 * scale

    val tableSpacing = 1 * scale

    val iconSizeLarge = 8.0 * scale
    val iconSizeMedium = 4.0 * scale
    val iconSizeSmall = 2.0 * scale

    val imgSizeMedium = 16 * scale
    val imgSizeLarge = 24 * scale

    val borderWidthButton = 0.2 * scale
    val borderRadius = 0.5 * scale

    val decoThickness = 0.15.rem
    val decoLink = TextDecoration.underline

    val footerFontLarge = Font(integer(600), fontSizeSmall, FontFamily.sansSerif)
    val footerFontSmall = Font(FontWeight.normal, fontSizeTiny, FontFamily.sansSerif)
    val footerGapSmall = 0.2 * scale
    val footerGapMedium = 0.4 * scale
    val footerGapLarge = 2 * scale
    val footerGapXLarge = 4 * scale

    val boxShadow = BoxShadow(0.rem, 0.rem, 11.px, (-2).px, rgb(0, 0, 0))
    val imgShadow = BoxShadow(0.rem, 0.1.rem, 4 * scale, 0.rem, rgb(0, 0, 0, 0.34))

    val bgPatternSize = 400.px

    data object Menu {
        val iconSizeMobile = iconSizeSmall
        val iconSize = iconSizeMedium
        val iconPadding = 1.rem
        val textColor = colorBright
    }

    val GlobalStyle = FC {
        Global {
            styles {
                "*" {
                    outlineWidth = 0.rem
                    borderWidth = 0.rem
                    margin = 0.rem
                    padding = 0.rem
                }
                body {
                    fontFamily = "'Poppins', sans-serif".unsafeCast<FontFamily>()
                    backgroundColor = colorAccent1
                }
            }
        }
    }

}