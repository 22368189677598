package components

import ContentPaths
import Device
import Paths
import data.BundledNews
import emotion.react.css
import io.ktor.http.*
import io.ktor.http.Url
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import web.cssom.*
import web.window.WindowTarget
import web.window.window

external interface NewsProps : Props {
    var allNews: List<BundledNews>
    var device: StateInstance<Device>
}

val News = FC<NewsProps> { props ->

    div {
        css {
            padding = Padding(Style.paddingTopBotMedium, Style.sitePadding)
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.center
            gap = Style.paddingTopBotMedium
            color = Style.colorAccent2

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }

        for (news in props.allNews) {
            val index = props.allNews.indexOf(news)
            div {
                css {
                    position = Position.relative
                    display = Display.flex
                    maxWidth = Style.maxContentWidth
                    flexDirection = FlexDirection.row
                    flexWrap = FlexWrap.wrap
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                    gap = Style.paddingTopBotSmall
                }

                img {
                    css {
                        width = if(props.device.component1() == Device.DESKTOP) Style.imgSizeLarge else Style.imgSizeMedium
                        maxWidth = (Style.maxContentWidth - Style.paddingTopBotMedium) / 2
                        if (news.image != null) {
                            boxShadow = Style.imgShadow
                        }
                    }
                    alt = "BB News"
                    src = if (news.image != null) {
                        news.image.content64
                    } else {
                        Paths.IMG_BB_NEWS
                    }
                }

                div {
                    css {
                        width = if(props.device.component1() == Device.DESKTOP) Style.imgSizeLarge else Style.imgSizeMedium
                        maxWidth = (Style.maxContentWidth - Style.paddingTopBotMedium) / 2
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.start
                        alignItems = AlignItems.stretch
                        gap = Style.paddingTopBotTiny
                    }

                    a {
                        css {
                            font = Style.fontQuote
                            whiteSpace = WhiteSpace.normal
                            overflowWrap = OverflowWrap.breakWord
                            textAlign = TextAlign.left
                        }
                        +news.news.title
                    }
                    p {
                        css {
                            font = Style.fontSmall
                            whiteSpace = WhiteSpace.preWrap
                            overflowWrap = OverflowWrap.breakWord
                            textAlign = TextAlign.left
                        }
                        +news.news.text
                    }
                }
            }
            if (index < props.allNews.size - 1) {
                div {
                    css {
                        borderTop = Border(1.px, LineStyle.solid, Style.colorAccent2Light)
                        borderBottom = Border(1.px, LineStyle.solid, Style.colorAccent2Light)
                        width = (50.pct)
                        maxWidth = Style.maxContentWidth
                    }
                }
            }
        }

        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                flex = number(1.0)
                width = Style.siteContentWidth
                maxWidth = Style.maxContentWidth
                flexWrap = FlexWrap.wrap
                gap = Style.paddingTopBotTiny
            }

            for (socialLink in SocialLink.entries) {
                div {
                    css {
                        cursor = Cursor.pointer
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        gap = important(0.px)
                    }
                    img {
                        css {
                            padding = Style.paddingButtonSmall
                            height = Style.iconSizeSmall
                        }
                        alt = socialLink.displayName
                        src = socialLink.iconPath
                    }
                    if (props.device.component1() == Device.DESKTOP) {
                        a {
                            css {
                                font = Style.fontSmall
                            }
                            +socialLink.displayName
                        }
                    }
                    onClick = {
                        window.open(socialLink.url, WindowTarget._blank)
                    }
                }
            }
        }
    }


}

enum class SocialLink(val displayName: String, val iconPath: String, val url: String) {
    YOUTUBE("YouTube", Paths.ICON_YOUTUBE, "https://www.youtube.com/@birkertbande"),
    SPOTIFY("Spotify", Paths.ICON_SPOTIFY, "https://open.spotify.com/intl-de/artist/7LzdbU8NBvrYhlVkyOd5UZ?si=ZqaTTtIZR-idybmf57hQ3g"),
    INSTAGRAM("Instagram", Paths.ICON_INSTA, "https://www.instagram.com/birkertbande")
}

