package pages

import components.Quote
import components.UserCarousel
import react.FC
import web.window.WindowTarget
import web.window.window

class BandPage : Page() {
    override val name: String = "Bande"

    override val fc: FC<PageProps> = FC<PageProps> { props ->
        Quote {
            this.device = props.device
            this.pageTitle = if (props.device.component1() == Device.PHONE) name else null
            this.titleText = if (props.device.component1() == Device.PHONE) {
                null
            } else {
                "Über uns"
            }
            this.boldText = null
            this.normalText = """
                Wir, die Birkert Bande, sind fünf Geschwister, die zusammen mit der Musik aufgewachsen sind. 
                Begonnen mit der musikalischen Gestaltung von Gottesdiensten und Engagements zu verschiedensten Feierlichkeiten geben wir inzwischen regelmäßig eigene Konzerte. Dabei ist das abwechslungsreiche Programm vokal und instrumental, klassisch und modern, heiter und nachdenklich. 
                Neben dem Studium oder der Schule sind wir in verschiedenen Ensembles aktiv und durch die gemeinsame Musik verlieren wir uns dabei nie aus den Augen.
                
                Ihr wollt noch mehr über uns erfahren? Dann schaut beim nächsten Konzert oder auf YouTube vorbei!
            """.trimIndent()
            this.linkText = """
                Hier geht’s zu unserem Youtube Kanal
            """.trimIndent()
            this.linkOnClick = {
                window.open("https://www.youtube.com/@birkertbande7669", WindowTarget._blank)
            }
        }

        UserCarousel {
            this.allMembers = props.allMembers
            this.device = props.device
        }

    }
}