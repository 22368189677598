package models.user

import kotlinx.serialization.Serializable

/**
 * BBUser (ID, Name, Bild, Beschreibung)
 */
@Serializable
data class BBUserInfo (val id: Int, val name: String, val imageID: Int, val description: String)

/**
 * Public Description
 */

/**
 *
 * GENERAL
 *
 * Konzert Ankündigung (automatisieren / vereinfachen) (Fokus Ankündigung, Einlasse, ...)
 * Newsletter (verschicken Interface)
 * Song (Fokus auf Aufnahmestatus)
 * News (Titel, Bild, Text, Ablaufdatum)
 *
 * Video ( , url, )
 *
 */