package components

import Device
import elements.TextButton
import emotion.react.css
import js.uri.encodeURIComponent
import kotlinx.browser.window
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import web.cssom.*

external interface ContactProps : Props {
    var device: StateInstance<Device>
    var text: String
    var pageTitle: String?
}

val Contact = FC<ContactProps> { props ->

    ReactHTML.div {
        css {
            padding = Padding(if (props.device.component1() == Device.PHONE) Style.paddingTopBotMedium else Style.paddingTopBotLarge, Style.sitePadding)
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.center
            color = Style.colorBright
            gap = Style.paddingTopBotTiny

            ReactHTML.a {
                textAlign = TextAlign.center
            }

            ReactHTML.p {
                maxWidth = 100.ch
                textAlign = TextAlign.center
                whiteSpace = WhiteSpace.preWrap
                wordBreak = WordBreak.breakWord
            }
        }

        ReactHTML.div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.start
                gap = Style.paddingTopBotTiny
            }
            props.pageTitle?.let {
                a {
                    css {
                        font = Style.fontTitle
                        opacity = number(0.5)
                    }
                    +it.uppercase()
                }
            }
            ReactHTML.p {
                css {
                    whiteSpace = WhiteSpace.preWrap
                    overflowWrap = OverflowWrap.breakWord
                    font = Style.fontQuote
                    textAlign = TextAlign.center
                }
                +props.text
            }

            TextButton {
                this.text = "per Mail"
                this.onBright = false
                this.onClickEvent = {
                    val mail = "birkert-bande@web.de"
                    val subject = "Anfrage"
                    val body = """
                    
                    ...
                    Ihr Ansprechpartner: Florentin Birkert
                    ...
                    
                """.trimIndent()
                    val mailTo = "mailto:$mail?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}"
                    window.location.href = mailTo
                }
            }
        }
    }

}