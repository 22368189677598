package pages

import JSTools.smoothScrollTop
import components.BigLogoWithImg
import components.Info
import components.News
import react.FC

class HomePage : Page() {
    override val name: String = "Home"
    override val iconPath: String = Paths.ICON_BB_MARK
    override val fc: FC<PageProps> = FC<PageProps> { props ->

        BigLogoWithImg {
            this.device = props.device
        }

        Info {
            this.device = props.device
            this.infoText = "Die Birkert Bande\nfünf Geschwister, die gemeinsam musizieren"
            this.linkText = "Erfahre mehr über unsere Bande"
            this.linkOnClick = {
                props.currPage.component2().invoke(Link.BAND)
                smoothScrollTop()
            }
            this.button1Text = "Unsere nächsten Konzerte"
            this.button2Text = "Unsere Musik"
            this.button1Click = {
                props.currPage.component2().invoke(Link.CONCERT)
                smoothScrollTop()
            }
            this.button2Click = {
                props.currPage.component2().invoke(Link.MUSIC)
                smoothScrollTop()
            }
        }

        News{
            this.allNews = props.allNews
            this.device = props.device
        }

    }
}