package pages

import components.Quote
import components.Spotify
import components.Youtube
import react.FC

class MusicPage : Page() {
    override val name: String = "Musik"
    override val fc: FC<PageProps> = FC<PageProps> { props ->

        Quote {
            this.device = props.device
            this.pageTitle = if (props.device.component1() == Device.PHONE) name else null
            this.titleText = "Musik"
            this.boldText = """
                Ob acapella-Gesang oder Jazz-Combo, ob Saiten streichen oder zupfen, ob Klassik oder Jazz: Unsere Musik ist so vielseitig, wie wir fünf Geschwister.
                Das abwechslungsreiche Programm arrangieren oder komponieren wir für unsere Besetzung selbst.
            """.trimIndent()
            this.normalText = """
                Neugierig geworden? Dann hört doch mal rein!
            """.trimIndent()
            this.linkText = null
            this.linkOnClick = null
        }

        Spotify {
            device = props.device
        }

        Quote {
            this.device = props.device
            this.pageTitle = null
            this.titleText = null
            this.boldText = """
                Neben aufwändig selbst produzierten Musikvideos gibt es auf YouTube auch die Reihe „Offline“ zu entdecken. Hier seht ihr, wie es hinter den Kulissen bei uns zugeht:
                Manchmal etwas chaotisch, aber immer mit viel Humor.
            """.trimIndent()
            this.normalText = """
                Auch hier könnt ihr sehr gerne mal reinschauen!
            """.trimIndent()
            this.linkText = null
            this.linkOnClick = null
        }

        Youtube {
            device = props.device
        }
    }
}