package components

import Device
import data.BundledMember
import emotion.react.css
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.*
import web.html.HTMLDivElement
import kotlin.math.abs

external interface UserCarouselProps : Props {
    var allMembers: List<BundledMember>
    var device: StateInstance<Device>
}

val UserCarousel = FC<UserCarouselProps>() { props ->

    val (currentFocus, setCurrentFocus) = useState<Int>(props.allMembers.size / 2)

    val userW = 290
    val userP = 15
    val userContainerW = userW + 2 * userP
    val userWidth = userW.px
    val userPadding = userP.px
    val userContainerWidth = userWidth + 2 * userPadding
    val verticalContainerPadding = (50.pct - userContainerWidth / 2)
    val container = useRef<HTMLDivElement>()
    val scrollable = useRef<HTMLDivElement>()

    var touchStartX = 0.0
    var touchEndX = 0.0

    fun swipe() {
        val swipeThreshold = 50.0

        val swipeDistance = touchEndX - touchStartX

        if (abs(swipeDistance) < swipeThreshold) return

        if (swipeDistance > 0) {
            // Swipe Right
            if (currentFocus > 0) {
                setCurrentFocus(currentFocus - 1)
            }
        }

        if (swipeDistance < 0) {
            // Swipe Left
            if (currentFocus < props.allMembers.size - 1) {
                setCurrentFocus(currentFocus + 1)
            }
        }
    }

    div {
        ref = container
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
            alignItems = AlignItems.stretch
            width = 100.pct

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }

        div {
            // Slides
            ref = scrollable
            css {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.stretch
                justifyContent = JustifyContent.start
                flexWrap = FlexWrap.nowrap
                overflowX = Overflow.hidden
                gap = userPadding

                width = 100.pct - 2 * verticalContainerPadding
                paddingLeft = verticalContainerPadding
                paddingRight = verticalContainerPadding
                paddingTop = Style.paddingTopBotSmall
                paddingBottom = Style.paddingTopBotTiny
                scrollBehavior = ScrollBehavior.smooth
            }

            for (member in props.allMembers) {
                val index = props.allMembers.indexOf(member)
                User {
                    this.user = member
                    this.width = userWidth
                    this.padding = userPadding
                    this.key = member.userInfo.name
                    this.onClick = {
                        setCurrentFocus(index)
                    }
                }
            }

            onTouchStart = {
                touchStartX = it.touches[0].clientX
            }
            onTouchEnd = {
                touchEndX = it.touches[0].clientX
                swipe()
            }
        }

        div {
            // buttons
            css {
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                paddingTop = Style.paddingTopBotTiny
                paddingBottom = Style.paddingTopBotSmall
                flexWrap = FlexWrap.wrap
                gap = 0.5 * userPadding
            }

            for (member in props.allMembers) {
                val index = props.allMembers.indexOf(member)
                if (member.image != null) {
                    img {
                        css {
                            cursor = Cursor.pointer
                            height = Style.iconSizeMedium
                            padding = 0.1 * Style.iconSizeMedium
                            background = Style.colorBright
                            if (index == currentFocus) {
                                boxShadow = Style.boxShadow
                            }
                            transition = Transition(TransitionProperty.all, 0.3.s, TransitionTimingFunction.ease)
                        }
                        alt = member.userInfo.name
                        src = member.image.content64
                        onClick = {
                            setCurrentFocus(index)
                        }
                    }
                } else {
                    a {
                        css {
                            cursor = Cursor.pointer
                            font = Style.fontQuote
                            color = Style.colorAccent2
                        }
                        +(member.userInfo.name.firstOrNull()?.toString() ?: "Member")
                        onClick = {
                            setCurrentFocus(index)
                        }
                    }
                }
            }
        }
    }
    useEffect(currentFocus) {
        scrollable.current?.scrollTo(0.0 + currentFocus * (userContainerW + userP), 0.0)
    }
}

