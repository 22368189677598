package models

import kotlinx.serialization.Serializable

@Serializable
data class News(
    val id: Int,
    val title: String,
    val imageID: Int,
    val text: String,
    val startDate: String,
    val expirationDate: String
)
