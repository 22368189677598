import react.create
import react.dom.client.createRoot
import web.dom.document
import web.html.HTMLElement


fun main(){
    val root = document.getElementById("root") as HTMLElement

    console.log("Hello from BB")

    val app = App.create{

    }

    createRoot(root).render(app)
}