package components

import Device
import emotion.react.css
import models.Concert
import models.Image
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.*
import kotlin.js.Date

external interface ConcertListProps : Props {
    var device: StateInstance<Device>
    var concerts: List<BundledConcert>
}

data class BundledConcert(val concert: Concert, val image: Image?, val date: BBDate, val location: BBLocation)

data class BBDate(val year: Int, val month: Int, val day: Int) {

    constructor(date: Date) : this(date.getFullYear(), date.getMonth(), date.getDate())

    fun getWeekday(): DayOfWeek? {
        val date = Date(year, month, day)
        val weekdayIndex = date.getDay()
        val type = if (weekdayIndex < DayOfWeek.entries.size) {
            DayOfWeek.entries.firstOrNull { it.ordinal == weekdayIndex }
        } else {
            null
        }
        console.log("$this is a [${type?.displ}]")
        return type
    }

    fun getMonth(): Month? {
        return Month.entries.getOrNull(month - 1)
    }

    override fun toString(): String {
        return "$year-$month-$day"
    }
}

data class BBLocation(val location: String?) {

    val name: String
    val street: String
    val town: String
    val state: String

    init {
        if (location == null) {
            console.error("Location is missing!")
            name = "missing"
            street = "missing"
            town = "missing"
            state = "missing"
        } else {
            val splitted = location.split("\n")
            if (splitted.size != 4) {
                console.error("$location\n\n Has not the right format!")
                name = "couldn't resolve"
                street = "couldn't resolve"
                town = "couldn't resolve"
                state = "couldn't resolve"
            } else {
                name = splitted[0]
                street = splitted[1]
                town = splitted[2]
                state = splitted[3]
            }
        }

    }
}

enum class DayOfWeek(val displ: String) {
    SUNDAY("Sonntag"),
    MONDAY("Montag"),
    TUESDAY("Dienstag"),
    WEDNESDAY("Mittwoch"),
    THURSDAY("Donnerstag"),
    FRIDAY("Freitag"),
    SATURDAY("Samstag")
}

enum class Month(val displ: String) {
    JANUARY("Januar"),
    FEBRUARY("Februar"),
    MARCH("März"),
    APRIL("April"),
    MAY("Mai"),
    JUNE("Juni"),
    JULY("Juli"),
    AUGUST("August"),
    SEPTEMBER("September"),
    OCTOBER("Oktober"),
    NOVEMBER("November"),
    DECEMBER("Dezember")
}

val ConcertList = FC<ConcertListProps> { props ->

    val (currDetails, setCurrDetails) = useState<BundledConcert>()


    div {
        // Zeilen Container
        css {
            position = Position.relative
            width = 100.pct - 2 * Style.sitePadding

            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            padding = Padding(Style.paddingTopBotSmall, Style.sitePadding)
            gap = Style.paddingTopBotTiny

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }
        if (props.concerts.isNotEmpty()) {
            table {
                css {
                    flex = number(1.0)
                    maxWidth = Style.maxContentWidth
                    width = 100.pct
                    borderCollapse = BorderCollapse.separate
                    borderSpacing = Padding(Style.paddingButtonSmall, Style.tableSpacing)
                }
                thead {
                    tr {
                        th {
                            colSpan = 2
                            css {
                                font = Style.fontQuote
                                color = Style.colorAccent2
                                textAlign = TextAlign.left
                                marginTop = Style.tableSpacing
                            }
                            +"WANN?"
                        }
                        th {
                            css {
                                font = Style.fontQuote
                                color = Style.colorAccent2
                                textAlign = TextAlign.left
                                marginTop = Style.tableSpacing
                            }
                            +"WO?"
                        }
                    }
                }
                tbody {
                    var lastYear = props.concerts.first().date.year
                    for (concert in props.concerts) {
                        if (lastYear != concert.date.year) {
                            tr {
                                td {
                                    css {
                                        position = Position.relative
                                        marginTop = Style.tableSpacing
                                    }
                                    colSpan = 4
                                    div {
                                        css {
                                            display = Display.flex
                                            flexDirection = FlexDirection.row
                                            alignItems = AlignItems.center
                                            justifyContent = JustifyContent.start
                                            gap = Style.paddingButtonSmall
                                        }
                                        a {
                                            css {
                                                font = Style.fontQuote
                                                color = Style.colorAccent2
                                            }
                                            +concert.date.year.toString()
                                        }
                                        div {
                                            css {
                                                borderTop = Border(1.5.px, LineStyle.solid, Style.colorAccent2Light)
                                                borderBottom = Border(1.5.px, LineStyle.solid, Style.colorAccent2Light)
                                                flex = number(1.0)
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        tr {
                            td {
                                css {
                                    textAlign = TextAlign.right
                                    fontSize = Style.fontSizeLarge
                                    color = Style.colorAccent2
                                    width = 3.rem
                                    fontWeight = integer(500)
                                    marginTop = Style.tableSpacing
                                }
                                +concert.date.day.toString().padStart(2, '0')
                            }
                            td {
                                css {
                                    position = Position.relative
                                    marginTop = Style.tableSpacing
                                }
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        alignItems = AlignItems.start
                                        gap = 0.px
                                    }
                                    a {
                                        css {
                                            fontSize = Style.fontSizeSmall
                                            color = Style.colorAccent2
                                        }
                                        +(concert.date.getMonth()?.displ ?: concert.date.month.toString())
                                    }
                                    a {
                                        css {
                                            fontSize = Style.fontSizeSmall
                                            color = Style.colorAccent2
                                        }
                                        +concert.date.year.toString() // (concert.date.getWeekday()?.displ ?: "")
                                    }
                                }
                            }
                            td {
                                css {
                                    position = Position.relative
                                    marginTop = Style.tableSpacing
                                }
                                div {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        alignItems = AlignItems.start
                                        gap = 0.px
                                    }
                                    a {
                                        css {
                                            fontSize = Style.fontSizeSmall
                                            color = Style.colorAccent2
                                        }
                                        +concert.location.street
                                    }
                                    a {
                                        css {
                                            fontSize = Style.fontSizeSmall
                                            color = Style.colorAccent2
                                        }
                                        +concert.location.town
                                    }
                                }
                            }
                            if (props.device.component1() != Device.PHONE) {
                                td {
                                    css {
                                        cursor = Cursor.pointer
                                        position = Position.relative
                                        textAlign = TextAlign.center
                                        fontSize = Style.fontSizeSmall
                                        marginTop = Style.tableSpacing

                                        border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent2)
                                        backgroundColor = Style.colorAccent2
                                        color = Style.colorBright

                                        hover {
                                            backgroundColor = Style.colorBright
                                            color = Style.colorAccent2
                                        }
                                        active {
                                            border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                                            backgroundColor = Style.colorAccent3
                                            color = Style.colorBright
                                        }
                                    }
                                    +"Details & Plakate"

                                    this.onClick = {
                                        setCurrDetails(concert)
                                    }
                                }
                            }
                        }
                        if (props.device.component1() == Device.PHONE) {
                            tr {
                                td {
                                    colSpan = 3
                                    css {
                                        cursor = Cursor.pointer
                                        position = Position.relative
                                        textAlign = TextAlign.center
                                        fontSize = Style.fontSizeSmall
                                        marginTop = Style.tableSpacing

                                        border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent2)
                                        backgroundColor = Style.colorAccent2
                                        color = Style.colorBright

                                        padding = 6.px

                                        hover {
                                            backgroundColor = Style.colorBright
                                            color = Style.colorAccent2
                                        }
                                        active {
                                            border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                                            backgroundColor = Style.colorAccent3
                                            color = Style.colorBright
                                        }
                                    }
                                    +"Details & Plakate"

                                    this.onClick = {
                                        setCurrDetails(concert)
                                    }
                                }
                            }

                            if (concert != props.concerts.last()) {
                                tr {
                                    td {
                                        colSpan = 3
                                        css {
                                            position = Position.relative
                                        }
                                        span {
                                            css {
                                                display = Display.block
                                                borderRadius = 2.px
                                                width = 70.pct
                                                marginLeft = 15.pct
                                                marginRight = 15.pct
                                                height = 4.px
                                                backgroundColor = Style.colorAccent2Light
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        lastYear = concert.date.year
                    }
                }
            }
        } else {
            a {
                css {
                    color = Style.colorAccent2
                    font = Style.fontQuote
                }
                +"Aktuell keine geplanten Konzerte".uppercase()
            }

            img {
                css {
                    width = if (props.device.component1() == Device.DESKTOP) Style.imgSizeLarge else Style.imgSizeMedium
                }
                src = Paths.IMG_BB_CONCERT
            }
        }
    }

    if (currDetails != null) {
        div {
            css {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                display = Display.flex
                flexDirection = if (props.device.component1() == Device.PHONE) {
                    FlexDirection.column
                } else {
                    FlexDirection.row
                }
                justifyContent = JustifyContent.stretch
                alignItems = AlignItems.stretch

                backgroundColor = Style.colorBright
                boxShadow = Style.boxShadow
                zIndex = integer(1)
            }

            img {
                css {
                    position = Position.absolute
                    top = Style.paddingButtonSmall
                    right = Style.paddingButtonSmall
                    cursor = Cursor.pointer
                    height = Style.iconSizeSmall
                    filter = invert(0.pct)

                    if (props.device.component1() == Device.PHONE) {
                        borderRadius = Style.borderRadius
                        backdropFilter = blur(10.px)
                        backgroundColor = rgb(0, 0, 0, 0.4)
                    }

                    zIndex = integer(2)
                }

                src = if (props.device.component1() == Device.PHONE) {
                    Paths.ICON_CLOSE_BRIGHT
                } else {
                    Paths.ICON_CLOSE_DARK
                }

                onClick = {
                    setCurrDetails(null)
                }
            }

            if (currDetails.image != null) {
                img {
                    css {
                        width = if (props.device.component1() == Device.PHONE) {
                            100.pct
                        } else {
                            50.pct
                        }
                    }
                    src = currDetails.image.content64
                }
            }
            div {
                css {
                    flex = number(1.0)
                    position = Position.relative

                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.start
                    alignItems = AlignItems.stretch

                    paddingTop = Style.paddingButtonSmall
                    paddingBottom = Style.paddingButtonSmall
                    paddingLeft = Style.paddingButtonSmall
                    paddingRight = Style.paddingButtonSmall

                    if (props.device.component1() == Device.PHONE) minWidth = 300.px

                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        height = Style.iconSizeSmall
                        gap = 0.px
                    }
                    a {
                        css {
                            fontSize = Style.fontSizeMedium
                            fontWeight = FontWeight.bolder
                            color = Style.colorAccent2
                            textAlign = TextAlign.left
                        }
                        +"Konzert Details".uppercase()
                    }
                }
                a {
                    css {
                        marginTop = Style.paddingTopBotTiny
                        fontSize = Style.fontSizeMedium
                        fontWeight = FontWeight.bold
                        color = Style.colorAccent2
                        textAlign = TextAlign.left
                    }

                    +currDetails.concert.concertTitle
                }
                a {
                    css {
                        marginTop = Style.paddingTopBotTiny
                        fontSize = Style.fontSizeMedium
                        fontWeight = FontWeight.bold
                        color = Style.colorAccent2
                        textAlign = TextAlign.left
                    }
                    +"${currDetails.concert.startsAt} Uhr, ${currDetails.date.day}.${currDetails.date.month}.${currDetails.date.year}"
                }
                p {
                    css {
                        fontSize = Style.fontSizeSmall
                        color = Style.colorAccent2
                        textAlign = TextAlign.left
                        whiteSpace = WhiteSpace.preWrap
                        overflowWrap = OverflowWrap.breakWord
                    }
                    +currDetails.concert.location
                }
                p {
                    css {
                        marginTop = Style.paddingTopBotTiny
                        fontSize = Style.fontSizeSmall
                        color = Style.colorAccent2
                        textAlign = TextAlign.left
                        whiteSpace = WhiteSpace.preWrap
                        overflowWrap = OverflowWrap.breakWord
                    }
                    +currDetails.concert.publicMessage
                }


            }

        }

    }


}