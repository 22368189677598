package data

import AuthConstants
import kotlinx.coroutines.await
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.encodeToJsonElement
import models.user.LoginBody
import models.user.UserInformation
import web.storage.localStorage
import web.xhr.XMLHttpRequest
import web.xhr.XMLHttpRequestResponseType
import kotlin.js.Promise


object HTTP {

    suspend fun get(
        url: String,
        body: JsonElement? = null,
        async: Boolean = true,
        responseType: XMLHttpRequestResponseType = XMLHttpRequestResponseType.text
    ): Promise<XMLHttpRequest> {
        val promise = Promise<XMLHttpRequest> { resolve, reject ->
            val xhr = XMLHttpRequest()
            xhr.responseType = responseType
            xhr.open("GET", url, async)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

            xhr.onload = {
                resolve(xhr)
            }
            if (body != null) {
                xhr.send(body.toString())
            } else {
                xhr.send()
            }
        }

        return promise
    }

    suspend inline fun <reified T> get(url: String, body: JsonElement? = null, async: Boolean = true): Promise<T?> {
        val promise = Promise<T?> { resolve, reject ->
            val xhr = XMLHttpRequest()
            xhr.responseType = XMLHttpRequestResponseType.text
            xhr.open("GET", url, async)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

            /* xhr.responseType = XMLHttpRequestResponseType.json // instead of JSON.parse()*/
            xhr.onload = {
                try {
                    val resolved = Json.decodeFromString<T>(xhr.response.toString())
                    resolve(resolved)
                } catch (e: Exception) {
                    resolve(null)
                }
            }
            if (body != null) {
                xhr.send(body.toString())
            } else {
                xhr.send()
            }
        }
        return promise
    }

    suspend fun post(
        url: String,
        body: JsonElement? = null,
        async: Boolean = true,
        responseType: XMLHttpRequestResponseType = XMLHttpRequestResponseType.text
    ): Promise<XMLHttpRequest> {

        val promise = Promise<XMLHttpRequest> { resolve, reject ->
            val xhr = XMLHttpRequest()
            xhr.responseType = responseType
            xhr.open("POST", url, async)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

            xhr.onload = {
                resolve(xhr)
            }

            if (body != null) {
                xhr.send(body.toString())
            } else {
                xhr.send()
            }

        }
        return promise
    }

    suspend fun put(
        url: String,
        body: JsonElement? = null,
        async: Boolean = true,
        responseType: XMLHttpRequestResponseType = XMLHttpRequestResponseType.text
    ): Promise<XMLHttpRequest> {
        val promise = Promise<XMLHttpRequest> { resolve, reject ->
            val xhr = XMLHttpRequest()
            xhr.responseType = responseType
            xhr.open("PUT", url, async)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

            xhr.onload = {
                resolve(xhr)
            }

            if (body != null) {
                xhr.send(body.toString())
            } else {
                xhr.send()
            }
        }
        return promise
    }

    suspend fun delete(
        url: String,
        body: JsonElement? = null,
        async: Boolean = true,
        responseType: XMLHttpRequestResponseType = XMLHttpRequestResponseType.text
    ): Promise<XMLHttpRequest> {
        val promise = Promise<XMLHttpRequest> { resolve, reject ->
            val xhr = XMLHttpRequest()
            xhr.responseType = responseType
            xhr.open("DELETE", url, async)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

            xhr.onload = {
                resolve(xhr)
            }

            if (body != null) {
                xhr.send(body.toString())
            } else {
                xhr.send()
            }
        }

        return promise
    }


}