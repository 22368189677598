package components

import ContentPaths
import Device
import JSTools.smoothScrollTop
import Link
import Style
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useState
import web.cssom.*
import web.dom.document
import web.window.window

external interface MenuProps : Props {
    var currPage: StateInstance<Link>
    var device: StateInstance<Device>
}

val Menu = FC<MenuProps> { props ->

    val (currPage, setCurrPage) = props.currPage
    val (onTop, setOnTop) = useState(true)
    val (showMobileNav, setShowMobileNav) = useState(false)

    document.onscroll = {
        setOnTop(window.scrollY == 0.0)
    }

    div {
        css {
            if (props.device.component1() == Device.DESKTOP) {
                padding = Padding(Style.Menu.iconPadding, Style.sitePadding)
                position = Position.fixed
                gap = Style.Menu.iconPadding
                if (!onTop) {
                    background = Style.colorBright
                    boxShadow = BoxShadow(0.rem, 0.rem, 1.rem, rgb(0, 0, 0, 0.96))
                }
            } else {
                padding = Style.paddingButtonMedium
                position = Position.absolute
                gap = Style.paddingButtonMedium
            }

            top = 0.px
            left = 0.px
            right = 0.px
            display = Display.flex
            flexDirection = FlexDirection.row
            alignItems = AlignItems.center
            justifyContent = JustifyContent.normal
            color = Style.Menu.textColor
            zIndex = integer(3)
            font = Style.fontMenu
            transition = Transition(TransitionProperty.all, 0.1.s, TransitionTimingFunction.ease)

            img {
                cursor = Cursor.pointer
                height = Style.Menu.iconSizeMobile
                textShadow = TextShadow(0.rem, 0.rem, 1.rem, rgb(0, 0, 0, 0.96))
            }
            a {
                cursor = Cursor.pointer

                textAlign = TextAlign.center
                if (props.device.component1() != Device.DESKTOP || onTop) {
                    textShadow = TextShadow(0.rem, 0.rem, 1.rem, rgb(0, 0, 0, 0.96))
                } else {
                    color = important(Style.colorAccent2)
                }

            }
        }

        img {
            if (props.device.component1() == Device.DESKTOP && !onTop) {
                src = ContentPaths.BB_MARK_DARK
            } else {
                src = ContentPaths.BB_MARK_LIGHT
            }
            alt = "BB"
            onClick = {
                setCurrPage(Link.entries.first { it.startPage })
                smoothScrollTop()
            }
        }
        div {
            css {
                flexGrow = number(1.0)
            }
        }

        if (props.device.component1() == Device.DESKTOP) {
            for (page in Link.entries.filter { !it.startPage && !it.hiddenFromMenu }) {
                a {
                    css {
                        if (page == currPage) {
                            textDecoration = TextDecoration.underline
                            textDecorationThickness = Style.decoThickness
                        }
                        hover {
                            textDecoration = TextDecoration.underline
                            textDecorationThickness = Style.decoThickness
                        }
                    }

                    +page.page.name.uppercase()

                    onClick = {
                        setCurrPage(page)
                        smoothScrollTop()
                    }
                }
            }
        } else {
            img {
                css {
                    filter = invert(100.pct)
                }
                alt = "Menu"
                src = Paths.ICON_BARS
                onClick = {
                    setShowMobileNav(!showMobileNav)
                    smoothScrollTop()
                }
            }
        }
    }
    if (showMobileNav) {
        div {
            css {
                position = Position.fixed
                top = 0.rem
                right = 0.rem
                left = 0.rem
                bottom = 0.rem
                backgroundColor = Style.colorAccent2
                color = Style.colorBright
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.start
                alignItems = AlignItems.stretch
                padding = Style.paddingButtonMedium
                gap = Style.paddingTopBotTiny
                zIndex = integer(5)
            }

            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.start
                    alignItems = AlignItems.center
                    padding = Padding(0.px, 0.px, Style.paddingTopBotTiny, 0.px)
                    gap = 0.px

                    img {
                        height = Style.Menu.iconSizeMobile
                    }
                }

                img {
                    src = ContentPaths.BB_MARK_LIGHT
                    alt = "BB"
                    onClick = {
                        setCurrPage(Link.entries.first { it.startPage })
                        setShowMobileNav(false)
                        smoothScrollTop()
                    }
                }
                div {
                    css {
                        flex = number(1.0)
                    }
                }
                img {
                    css {
                        filter = invert(100.pct)
                    }
                    alt = "Close"
                    src = Paths.ICON_CLOSE_DARK
                    onClick = {
                        setShowMobileNav(false)
                        smoothScrollTop()
                    }
                }
            }

            for (page in Link.entries.filter { !it.startPage && !it.hiddenFromMenu }) {
                a {
                    css {
                        font = Style.fontMenu
                        color = Style.colorBright
                        textAlign = TextAlign.left
                        padding = Padding(0.px, 0.px, 0.px, Style.paddingButtonSmall)
                        if (page == currPage) {
                            textDecoration = TextDecoration.underline
                            textDecorationThickness = Style.decoThickness
                        }
                        hover {
                            textDecoration = TextDecoration.underline
                            textDecorationThickness = Style.decoThickness
                        }
                    }

                    +page.page.name.uppercase()

                    onClick = {
                        setCurrPage(page)
                        setShowMobileNav(false)
                        smoothScrollTop()
                    }
                }
            }

            div {
                css {
                    flex = number(1.0)
                }
            }
        }
    }


}