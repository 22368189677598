package components

import ContentPaths
import Device
import Link
import Style
import data.BundledMember
import data.HTTP
import elements.TextButton
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.encodeToJsonElement
import models.drafts.NewsMailDraft
import models.user.BBUserInfo
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.col
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.ul
import react.useState
import web.cssom.*
import web.dom.document
import web.html.InputType
import web.prompts.alert

external interface FooterProps : Props {
    var device: StateInstance<Device>
    var currPage: StateInstance<Link>
    var members: List<BundledMember>
}

val Footer = FC<FooterProps> { props ->

    val (mailInput, setMailInput) = useState<String>("")

    div {
        css {
            backgroundColor = Style.colorAccent2
            color = Style.colorBright
            padding = Padding(Style.paddingTopBotSmall, Style.sitePadding)
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            gap = Style.footerGapXLarge
        }

        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.start
                justifyContent = JustifyContent.spaceBetween
                flexWrap = FlexWrap.wrap
                gap = Style.footerGapLarge

                div {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.start
                    alignItems = AlignItems.stretch
                    gap = Style.footerGapMedium

                    div {
                        gap = Style.footerGapSmall
                    }

                    a {
                        textAlign = TextAlign.left
                        font = Style.footerFontLarge
                    }

                    p {
                        textAlign = TextAlign.left
                        font = Style.footerFontSmall
                        whiteSpace = WhiteSpace.pre
                    }
                }
            }

            div {
                a {
                    +"Birkert Bande"
                }
                div {
                    for (member in props.members) {
                        p {
                            +member.userInfo.name
                        }
                    }
                }
            }
            div {
                a {
                    css {
                        cursor = Cursor.pointer
                        hover {
                            textDecoration = TextDecoration.underline
                        }
                    }
                    +"Impressum"

                    onClick = {
                        props.currPage.component2().invoke(Link.IMPRESSUM)
                        JSTools.smoothScrollTop()
                    }
                }
                div {
                    p {
                        +"Constantin Birkert"
                    }
                    p {
                        +"Forchenweg 18"
                    }
                    p {
                        +"70771 Leinfelden"
                    }
                    p {
                        css {
                            cursor = Cursor.pointer
                            textDecoration = Style.decoLink
                        }
                        +"birkert-bande@web.de"
                        onClick = {
                            window.location.href = "mailto:birkert-bande@web.de"
                        }
                    }
                }

            }
            div {
                css {
                    flex = number(1.0)
                }
                a {
                    +"Newsletter"
                }
                p {
                    +"So bleibt ihr auf dem Laufenden\nund verpasst keines unserer Konzerte."
                }
                div {
                    css {
                        flexDirection = important(FlexDirection.row)
                        gap = important(Style.footerGapMedium)
                    }

                    input {
                        css {
                            borderBottomWidth = Style.borderWidthButton
                            borderLeftWidth = 0.rem
                            borderTopWidth = 0.rem
                            borderRightWidth = 0.rem
                            borderColor = Style.colorBright
                            backgroundColor = rgb(0, 0, 0, 0.0)
                            color = Style.colorBright
                            font = Style.footerFontSmall
                            marginLeft = Style.borderWidthButton
                            marginTop = Style.borderWidthButton
                            marginRight = Style.borderWidthButton
                            borderStyle = LineStyle.solid
                            padding = Style.paddingButtonSmall
                            borderRadius = 0.px

                            focus {
                                marginLeft = 0.rem
                                marginTop = 0.rem
                                marginRight = 0.rem
                                borderLeftWidth = Style.borderWidthButton
                                borderTopWidth = Style.borderWidthButton
                                borderRightWidth = Style.borderWidthButton
                                caretColor = Style.colorBright
                            }
                        }
                        placeholder = "E-Mail Adresse"
                        type = InputType.email
                        value = mailInput
                        onChange = {
                            setMailInput(it.currentTarget.value)
                        }
                    }

                    TextButton {
                        this.text = "abonnieren"
                        this.onClickEvent = {
                            MainScope().launch {
                                if (mailInput.isNotEmpty()) {
                                    val response = HTTP.post(Paths.PUBLIC_BB_API_NEWSLETTER, Json.encodeToJsonElement(NewsMailDraft(mailInput))).await()
                                    if (response.status != 200) {
                                        alert("""Mail has already a subscription!""")
                                    } else {
                                        alert("""Subscribed with $mailInput!""".trimMargin())
                                    }
                                }
                            }
                        }
                        this.onBright = false
                    }

                }
            }

        }

        img {
            css {
                width = Style.iconSizeLarge
                height = Style.iconSizeLarge
                cursor = Cursor.pointer
            }

            src = ContentPaths.BB_MARK_LIGHT
            onClick = {
                JSTools.smoothScrollTop()
            }
        }
    }
    div {
        css {
            height = Style.paddingTopBotSmall

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_DARK)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }
    }

}