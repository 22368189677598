package components

import Device
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.*

external interface SendMailProps : Props {
    var device: StateInstance<Device>
}

val SendMail = FC<SendMailProps> { props ->
    div {
        css {
            padding = Padding(Style.paddingTopBotSmall, Style.sitePadding)
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.center
            gap = Style.paddingTopBotMedium
            color = Style.colorAccent2

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }

        img {
            css {
                height = if(props.device.component1() == Device.DESKTOP) Style.imgSizeLarge else Style.imgSizeMedium
            }

            src = Paths.IMG_BB_CONTACT

        }
    }
}