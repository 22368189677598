package components

import Device
import elements.TextButton
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.option
import react.useState
import web.cssom.*
import web.html.Loading

external interface YoutubeProps : Props {
    var device: StateInstance<Device>
}

val Youtube = FC<YoutubeProps>() { props ->

    val (embedLink, setEmbedLink) = useState<PlayList>(PlayList.Offline)

    ReactHTML.div {
        css {
            flexDirection = FlexDirection.column
            gap = Style.paddingTopBotTiny
            padding = Padding(Style.paddingTopBotMedium, Style.sitePadding)
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }



        ReactHTML.iframe {
            css {
                backgroundColor = Style.colorAccent2
                borderRadius = 0.px
                position = Position.relative
                when (props.device.component1()) {
                    Device.DESKTOP -> {
                        height = 512.px
                        width = (16.0 / 9.0) * 512.px
                    }

                    Device.PHONE -> {
                        height = 196.px
                        width = (16.0 / 9.0) * 196.px
                    }
                }

                minWidth = (16.0 / 9.0) * 196.px
                maxWidth = (16.0 / 9.0) * 512.px

                borderWidth = 0.px
            }
            title = "YT Playlist Player"
            allowFullScreen = true
            allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            src = "https://www.youtube-nocookie.com/embed/videoseries?${embedLink.urlAttrs}"
            loading = Loading.lazy
        }

        a {
            css {
                font = Style.fontSmall
                color = Style.colorAccent2
            }
            +"Playlist"
        }

        ReactHTML.select {
            css {
                cursor = Cursor.pointer
                font = Style.fontTextButton
                padding = Style.paddingButtonSmall
                textAlign = TextAlign.center
                borderRadius = 0.px

                color = Style.colorBright
                backgroundColor = Style.colorAccent2
                border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent2)

                hover {
                    backgroundColor = Style.colorBright
                    color = Style.colorAccent2
                }
                focus {
                    backgroundColor = Style.colorAccent3
                    color = Style.colorBright
                    border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                }
                option {
                    borderRadius = 0.px
                    backgroundColor = Style.colorBright
                    color = Style.colorAccent2

                    hover {
                        color = Style.colorBright
                        backgroundColor = Style.colorAccent2
                        border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent2)
                    }
                    focus {
                        backgroundColor = Style.colorAccent3
                        color = Style.colorBright
                        border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                    }
                }
            }

            option {
                disabled = true
                +"Playlist auswählen"
            }

            defaultValue = 0

            for (link in PlayList.entries) {
                option {
                    value = link.ordinal
                    +link.name
                }
            }

            onChange = { event ->
                val index = event.currentTarget.value.toIntOrNull()
                if (index != null) {
                    val entry = PlayList.entries.getOrNull(index)
                    entry?.let {
                        setEmbedLink(it)
                    }
                }
            }
        }
    }
}

enum class PlayList(val urlAttrs: String) {
    Offline("si=RwcNDvI0OHY9sVS8&amp;list=PLLDwbXfk-pNQpVXAsZUVw7qH3qZPzfUbw"),
    Musikvideos("si=DpQizUPxxmN6kdLX&amp;list=PLLDwbXfk-pNSnrOKZ-YyL35Mrv8i7tfDo")
}