import components.BBDate
import web.navigator.navigator
import web.window.window

object Tools {

    fun getDeviceType(): Device {
        val ua = navigator.userAgent
        if (kotlinx.browser.window.matchMedia("only screen and (max-width: 760px)").matches) {
            return Device.PHONE
        } else {
            return Device.DESKTOP
        }
    }

    fun getBBDateFromString(dateString: String): BBDate? {
        val dateElements = dateString.split("-")

        if (dateElements.size != 3) {
            console.error("Date has the wrong format! (yyyy-MM-dd)")
            return null
        }

        val year = dateElements[0].toIntOrNull()
        if (year == null || year <= 0) {
            console.error("${dateElements[0]} is not a valid year!")
            return null
        }
        val month = dateElements[1].toIntOrNull()
        if (month == null || month !in 1..12) {
            console.error("${dateElements[1]} is not a valid month!")
            return null
        }
        val day = dateElements[2].toIntOrNull()
        if (day == null || day !in 1..31) {
            console.error("${dateElements[2]} is not a valid day!")
            return null
        }

        return BBDate(year, month, day)
    }

}