package pages

import react.FC
import react.Key
import react.Props

abstract class Page {
    abstract val name: String
    open val iconPath: String? = null
    abstract val fc: FC<PageProps>
}