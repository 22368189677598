package components

import data.BundledMember
import emotion.react.css
import io.ktor.utils.io.core.*
import kotlinx.browser.window
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import web.cssom.*
import web.html.HTMLDivElement

external interface UserProps : Props {
    var user: BundledMember
    var width: Length
    var padding: Length
    var onClick: (MouseEvent<HTMLDivElement, *>) -> Unit
}

val User = FC<UserProps>() { props ->

    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.stretch
            this.padding = props.padding
            gap = props.padding
            width = important(props.width)
            maxWidth = important(props.width)
            minWidth = important(props.width)
            boxShadow = Style.boxShadow
            cursor = Cursor.pointer
            backgroundColor = Style.colorBright
        }
        if (props.user.image != null) {
            img {
                css {
                    width = 100.pct
                }
                src = props.user.image?.content64
            }
        }

        p {
            css {
                font = Style.fontQuote
                color = Style.colorAccent2
                whiteSpace = WhiteSpace.preWrap
                overflowWrap = OverflowWrap.breakWord
            }
            +props.user.userInfo.name.uppercase()
        }

        p {
            css {
                font = Style.fontSmall
                color = Style.colorAccent2
                whiteSpace = WhiteSpace.preWrap
                overflowWrap = OverflowWrap.breakWord
            }
            +props.user.userInfo.description
        }

        onClick = props.onClick

    }


}