package models

import kotlinx.serialization.Serializable
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

@Serializable
data class Image(
    val id: Int,
    val name: String,
    val content64: String
) {
    @OptIn(ExperimentalEncodingApi::class)
    fun getContentAsBytes(): ByteArray {
        return Base64.decode(content64)
    }

    @OptIn(ExperimentalEncodingApi::class)
    fun getContentAsString64(bytes: ByteArray): String {
        return Base64.encode(bytes)
    }
}