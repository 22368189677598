import components.*
import data.BundledMember
import data.BundledNews
import data.HTTP
import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import models.Concert
import models.Image
import models.News
import models.user.BBUserInfo
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import react.dom.html.ReactHTML.div
import react.*
import web.cssom.*
import kotlin.js.Date

val App = FC {

    val (iUpdate, setIUpdate) = useState(false)

    val device = useState<Device>(Tools.getDeviceType())
    val currPage = useState<Link>(Link.HOME)
    val members = useState<List<BundledMember>>(emptyList())
    val news = useState<List<BundledNews>>(emptyList())
    val concerts = useState<List<BundledConcert>>(emptyList())

    Style.GlobalStyle {}

    document.onresize = {
        device.component2().invoke(Tools.getDeviceType())
    }

    Menu {
        this.device = device
        this.currPage = currPage
    }

    div {
        css {
            position = Position.absolute
            width = 100.pct
            top = 0.rem
            left = 0.rem
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.stretch
            backgroundColor = Style.colorAccent1
        }

        currPage.component1().page.fc {
            this.device = device
            this.currPage = currPage
            this.allMembers = members.component1()
            this.allNews = news.component1()
            this.allConcerts = concerts.component1()
        }
        Footer {
            this.device = device
            this.currPage = currPage
            this.members = members.component1()
        }
    }

    useEffect(device) {
        console.log("Detected ${device.component1().name} Device!")
    }
    
    useEffect(iUpdate) {
        MainScope().launch {
            val newsEntrys = HTTP.get<List<News>>(Paths.PUBLIC_BB_API_NEWS).await()
            val bundledNews = newsEntrys?.map {
                val imageID = it.imageID
                val image = if (imageID != -1) {
                    HTTP.get<Image>(Paths.PUBLIC_BB_API_IMAGES + "/$imageID").await()
                } else {
                    null
                }
                BundledNews(it, image)
            } ?: emptyList()
            news.component2().invoke(bundledNews)

            val users = HTTP.get<List<BBUserInfo>>(Paths.PUBLIC_BB_API_MEMBERS).await()
            val bundledMembers = users?.map {
                val imageID = it.imageID
                val image = if (imageID != -1) {
                    HTTP.get<Image>(Paths.PUBLIC_BB_API_IMAGES + "/$imageID").await()
                } else {
                    null
                }
                BundledMember(it, image)
            } ?: emptyList()
            members.component2().invoke(bundledMembers)

            val concertEntrys = HTTP.get<List<Concert>>(Paths.PUBLIC_BB_API_CONCERTS).await()
            val bundledConcerts = concertEntrys?.map {
                val imageID = it.imageIDBanner
                val image = if (imageID != -1) {
                    HTTP.get<Image>(Paths.PUBLIC_BB_API_IMAGES + "/$imageID").await()
                } else {
                    null
                }
                val date = Tools.getBBDateFromString(it.date)
                if (date == null) {
                    BundledConcert(it, image, BBDate(Date()), BBLocation(it.location))
                } else {
                    BundledConcert(it, image, date, BBLocation(it.location))
                }

            } ?: emptyList()
            concerts.component2().invoke(bundledConcerts)
        }
    }
}