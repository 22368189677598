package components

import Device
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.events.MouseEvent
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import web.cssom.*
import web.html.HTMLParagraphElement

external interface QuoteProps : Props {
    var device: StateInstance<Device>
    var pageTitle: String?
    var titleText: String?
    var boldText: String?
    var normalText: String?
    var linkText: String?
    var linkOnClick: ((MouseEvent<HTMLParagraphElement, *>) -> Unit)?
}

val Quote = FC<QuoteProps> { props ->
    div {
        css {
            padding = Padding(if (props.device.component1() == Device.PHONE) Style.paddingTopBotMedium else Style.paddingTopBotLarge, Style.sitePadding)
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.start
            alignItems = AlignItems.center
            color = Style.colorBright
            gap = Style.paddingTopBotTiny

            a {
                textAlign = TextAlign.center
            }

            p {
                maxWidth = 100.ch
                textAlign = TextAlign.left
                whiteSpace = WhiteSpace.preWrap
                wordBreak = WordBreak.breakWord
            }
        }

        props.pageTitle?.let {
            a {
                css {
                    font = Style.fontTitle
                    opacity = number(0.5)
                }
                +it.uppercase()
            }
        }

        if (props.titleText != null) {
            a {
                css {
                    font = Style.fontQuote
                }
                +props.titleText
            }
        }
        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.start
                maxWidth = Style.maxContentWidth
                alignItems = AlignItems.stretch
                gap = Style.paddingTopBotTiny
            }
            if (props.boldText != null) {
                p {
                    css {
                        font = Style.fontQuote
                    }
                    +props.boldText
                }
            }

            if (props.normalText != null) {
                p {
                    css {
                        font = Style.fontSmall
                    }
                    +props.normalText
                }
            }

            if (props.linkText != null) {
                p {
                    css {
                        cursor = Cursor.pointer
                        font = Style.fontLink
                        textDecoration = Style.decoLink
                        transition = Transition(TransitionProperty.all, 0.3.s, TransitionTimingFunction.ease)
                        hover {
                            textDecoration = TextDecoration.wavy
                        }
                        focus {
                            color = Style.colorAccent3
                        }
                    }

                    +props.linkText

                    onClick = props.linkOnClick
                }
            }
        }
    }
}