package components

import ContentPaths
import Device
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.*

external interface BigLogoWithImgProps : Props {
    var device: StateInstance<Device>
}


val BigLogoWithImg = FC<BigLogoWithImgProps> { props ->

    div {
        css {
            position = Position.relative
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            height = (9.0 / 18.0) * 100.0.vw
        }

        div {
            css {
                position = Position.absolute
                width = 100.pct
                height = (9.0 / 18.0) * 100.0.vw
                top = 0.rem
                left = 0.rem
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                zIndex = integer(1)
                overflow = Overflow.hidden

            }
            img {
                css {
                    width = 100.pct
                    filter = array(brightness(70.pct), invert(8.pct))
                }
                alt = "Title Image"
                src = ContentPaths.TITLE_IMG
            }
        }

        div {
            css {
                position = Position.absolute
                width = 100.pct
                height = (9.0 / 18.0) * 100.0.vw
                top = 0.rem
                left = 0.rem
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                zIndex = integer(2)
                overflow = Overflow.hidden
            }
            img {
                css {
                    width = 50.pct
                    filter = dropShadow(0.rem, 0.rem, 1.rem, rgb(0,0,0,1.0))
                }
                alt = "Birkert Bande"
                src = ContentPaths.BB_LOGO_MAIN_LIGHT
            }
        }
    }
}