package pages

import components.Contact
import components.SendMail
import react.FC

class ContactPage : Page() {
    override val name: String = "Kontakt"
    override val fc: FC<PageProps> = FC<PageProps> { props ->
        Contact {
            this.device = props.device
            this.text = """Ob Konzertanfrage, runder Geburtstag oder ein unschlagbarer Witz, den wir noch nicht kennen – tretet mit uns in Kontakt!
                |
                |Flo freut sich über jede Nachricht.
                """.trimMargin()
            this.pageTitle = if (props.device.component1() == Device.PHONE) name else null
            this.key = "contactButton"
        }
        SendMail {
            this.key = "mailSend"
            this.device = props.device
        }
    }
}