package components

import Device
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import web.cssom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import web.cssom.BoxShadow
import web.cssom.Padding
import web.html.Loading
import kotlin.math.abs

external interface SpotifyProps : Props {
    var device: StateInstance<Device>
}

val Spotify = FC<SpotifyProps> { props ->

    div {
        css {
            padding = Padding(Style.paddingTopBotMedium, Style.sitePadding)
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center

            backgroundImage = url(Paths.IMG_BB_BGPATTERN_LIGHT)
            backgroundRepeat = BackgroundRepeat.repeat
            //backgroundAttachment = BackgroundAttachment.fixed
            backgroundSize = Style.bgPatternSize
        }

        iframe {
            css {
                backgroundColor = rgb(0, 0, 0, 0.0)
                borderRadius = Style.borderRadius
                position = Position.relative
                height = when(props.device.component1()) {
                    Device.DESKTOP -> {
                        352.px
                    }

                    Device.PHONE -> {
                        152.px
                    }
                }
                minWidth = (21.0 / 9.0) * 152.px

                width = (100.vw - 2 * Style.sitePadding)

                maxWidth = (21.0 / 9.0) * 352.px

                borderWidth = 0.px
            }
            allowFullScreen = false
            allow = "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            src = "https://open.spotify.com/embed/artist/7LzdbU8NBvrYhlVkyOd5UZ?utm_source=generator"
            loading = Loading.lazy
        }
    }

}