package models

import kotlinx.serialization.Serializable


/**
 *
 */
@Serializable
data class Concert(
    val concertId: Int,
    var date: String,
    var concertTitle: String,
    var location: String ,
    var startsAt: String ,
    var endsAt: String,
    var techRequested: Boolean,
    var privateDescription: String,
    var publicMessage: String,
    var imageIDBanner: Int,
    var public: Boolean
)


