package components

import Device
import Style
import elements.TextButton
import emotion.react.css
import react.FC
import react.Props
import react.StateInstance
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import web.cssom.*
import web.html.HTMLButtonElement
import web.html.HTMLParagraphElement


external interface InfoProps : Props {
    var device: StateInstance<Device>
    var infoText: String
    var linkText: String
    var linkOnClick: ((event: MouseEvent<HTMLParagraphElement, *>) -> Unit)
    var button1Text: String
    var button2Text: String
    var button1Click: ((event: MouseEvent<HTMLButtonElement, *>) -> Unit)
    var button2Click: ((event: MouseEvent<HTMLButtonElement, *>) -> Unit)
}

val Info = FC<InfoProps> { props ->

    div{
        css {
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            padding = Padding(Style.paddingTopBotSmall, Style.sitePadding)
        }

        div {
            css {
                color = Style.colorBright
                display = Display.flex
                maxWidth = Style.maxContentWidth
                gap = Style.paddingTopBotSmall
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.start
                alignItems = AlignItems.stretch
            }

            p {
                css {
                    font = Style.fontQuote
                    textAlign = TextAlign.center
                    whiteSpace = WhiteSpace.preWrap
                    wordBreak = WordBreak.breakWord
                }

                +props.infoText
            }

            p {
                css {
                    cursor = Cursor.pointer
                    font = Style.fontLink
                    textDecoration = Style.decoLink
                    textDecorationThickness = Style.decoThickness
                    whiteSpace = WhiteSpace.normal
                    wordBreak = WordBreak.breakWord
                    textAlign = TextAlign.center
                    hover {
                        textDecoration = TextDecoration.wavy
                    }
                    focus {
                        color = Style.colorAccent3
                    }
                }

                onClick = props.linkOnClick

                +props.linkText
            }

            div {
                css {
                    when(props.device.component1()){
                        Device.DESKTOP -> {
                            display = Display.grid
                            gap = Style.sitePadding
                            gridAutoFlow = GridAutoFlow.column
                            gridAutoColumns = 1.fr
                        }
                        Device.PHONE -> {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            gap = Style.paddingButtonSmall
                        }
                    }
                }

                TextButton {
                    this.text = props.button1Text
                    this.onClickEvent = props.button1Click
                    this.onBright = false
                }

                TextButton {
                    this.text = props.button2Text
                    this.onClickEvent = props.button2Click
                    this.onBright = false
                }
            }

        }
    }


}