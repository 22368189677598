package pages

import components.ConcertList
import components.Quote
import js.uri.encodeURIComponent
import kotlinx.browser.window
import react.FC

class ConcertPage : Page() {
    override val name: String = "Konzerte"
    override val fc: FC<PageProps> = FC<PageProps> { props ->

        Quote {
            this.device = props.device
            this.pageTitle = if(props.device.component1() == Device.PHONE) name else null
            this.titleText = "Konzerte"
            this.boldText = """
                Ob Klassik oder Jazz, ob acapella oder instrumental: 
                In unseren Konzerten könnt ihr unser abwechslungsreiches Programm und uns fünf Geschwister hautnah erleben. 
            """.trimIndent()
            this.normalText = null
            this.linkText = null
            this.linkOnClick = null
        }
        ConcertList{
            this.device = props.device
            this.concerts = props.allConcerts
        }

    }
}