import kotlinx.browser.window
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

object JSTools {

    fun smoothScrollTop() {
        window.scrollTo(ScrollToOptions(top = 0.0, left = 0.0, behavior = ScrollBehavior.SMOOTH))
    }

}