package elements

import emotion.react.css
import react.dom.html.ReactHTML.button
import react.FC
import react.Props
import react.dom.events.MouseEvent
import web.cssom.*
import web.html.HTMLButtonElement

external interface TextButtonProps : Props {
    var text: String
    var onClickEvent: ((event: MouseEvent<HTMLButtonElement, *>) -> Unit)
    var onBright: Boolean
}


val TextButton = FC<TextButtonProps> { props ->

    button {
        css {
            cursor = Cursor.pointer
            font = Style.fontTextButton
            padding = Style.paddingButtonSmall
            textAlign = TextAlign.center

            if (props.onBright) {
                color = Style.colorBright
                backgroundColor = Style.colorAccent2
                border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent2)
            } else {
                color = Style.colorBright
                backgroundColor = rgb(0, 0, 0, 0.0)
                border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorBright)
            }

            hover {
                if (props.onBright) {
                    backgroundColor = Style.colorBright
                    color = Style.colorAccent2
                } else {
                    backgroundColor = Style.colorBright
                    color = Style.colorAccent2
                }
            }
            active {
                if (props.onBright) {
                    border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                    backgroundColor = Style.colorAccent3
                    color = Style.colorBright
                } else {
                    border = Border(Style.borderWidthButton, LineStyle.solid, Style.colorAccent3)
                    backgroundColor = Style.colorAccent3
                    color = Style.colorBright
                }
            }
        }

        +props.text

        onClick = props.onClickEvent
    }
}
